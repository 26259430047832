<template>
    <div class="row" >
      <div class="col-md-6">
        <va-alert color="danger" class="mb-4"  v-model="erro" closeable  v-for="n in msg_erro" :key="n">
          <li>
            <b>{{n[0]}}</b>
          </li>
        </va-alert> 
      </div>
    </div>    
</template>
<script>
export default {
  name: 'alertaErro',
  components: {
  },
  props: {
    erro: {
      default: 0 
    },
    msg_erro: {},
  },
  beforeMount(){

  }
};  
</script>  
